export type TextStreamStartedResponse = {
    timestamp: string;
}

export type TranscribeSpeechResponse = {
    speech_final: boolean
    transcription: string
}

export enum SSTErrorType {
    NOT_SUPPORTED = 'not_supported',
    RECONNECTION = 'reconnection',
    STREAM_FAILED = 'stream_failed',
    NOT_AVAILABLE = 'not_available',
    TIME_LIMIT = 'time_limit'
}

export enum TTSErrorType {
    NOT_SUPPORTED = 'not_supported',
    RECONNECTION = 'reconnection',
    STREAM_FAILED = 'stream_failed',
    NOT_AVAILABLE = 'not_available',
}

export interface ISSTErrorResponse {
    type: SSTErrorType,
    message: string
}

export interface ITTSErrorResponse {
    type: TTSErrorType,
    message: string
}
