import React, {ReactNode} from "react";
import {SocketProvider} from "./SocketContext";
import {AudioOutputControlsProvider} from "./AudioOutputControlsContext";
import {UserSettingsProvider} from "./UserSettingsContext";
import {ChatHistoryProvider} from "./ChatHistoryContext";
import {AudioInputControlsProvider} from "./AudioInputControlsContext";
import {GOOGLE_CLIENT_ID} from "../../app/const";
import {GoogleOAuthProvider} from "@react-oauth/google";
import {UserTradingProfileProvider} from "./UserTradingProfileContext";
import {AuthProvider} from "./AuthContext";

export const ContextProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    return (
        <AuthProvider>
            <SocketProvider>
                <AudioOutputControlsProvider>
                    <AudioInputControlsProvider>
                        <UserSettingsProvider>
                            <UserTradingProfileProvider>
                                <ChatHistoryProvider>
                                    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                                        {children}
                                    </GoogleOAuthProvider>
                                </ChatHistoryProvider>
                            </UserTradingProfileProvider>
                        </UserSettingsProvider>
                    </AudioInputControlsProvider>
                </AudioOutputControlsProvider>
            </SocketProvider>
        </AuthProvider>
    );
}