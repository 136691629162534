export const parseTimestampString = (timestamp: string) => {
    const date = new Date(timestamp);
    return parseDate(date);
}

export const parseDate = (date: Date) => {
    return `${date.toLocaleString('en-US', {
        dateStyle: 'short',
        timeStyle: "short"
    })}`;
}

export const parseFileSize = (fileSize: number) => {
    const fileSizeKb = fileSize / 1024
    if (fileSizeKb < 500) {
        return `${(fileSizeKb).toFixed(2)} kB`;
    } else {
        return `${(fileSizeKb / 1024).toFixed(2)} MB`;
    }
}

export const truncateFileName = (fileName: string, maxLength: number) => {
    if (fileName.length <= maxLength || maxLength === 0) return fileName;
    const extension = fileName.split('.').pop() ?? '';
    const name = fileName.substring(0, fileName.lastIndexOf('.'));
    return `${name.substring(0, maxLength - 3 - extension.length)}...${extension}`;
};
