import React, {createContext, ReactNode, useContext, useEffect, useState} from "react";
import {useSocket} from "./SocketContext";
import {WEBSOCKET_RECEIVE_EVENTS} from "../../../constants/api";
import {UserSettings} from "../../types";

const UserSettingsContext = createContext({
    userSettings: {
        email: '',
        name: '',
        language: '',
        quiz_finished: true,
        picture: '',
        voice_response_enabled: false
    },
    setUserSettings: (prev: (prev: UserSettings) => UserSettings) => {}
});

export const UserSettingsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const socket = useSocket();
    const [userSettings, setUserSettings] = useState<UserSettings>({
        email: '',
        name: '',
        language: '',
        quiz_finished: true,
        picture: '',
        voice_response_enabled: false
    });

    useEffect(() => {
        const userSettingsListener = (response: string) => {
            const userSettingsResponse: UserSettings = JSON.parse(response)
            console.log(userSettingsResponse)
            setUserSettings(userSettingsResponse)
        };
        if (socket) {
            socket.on(WEBSOCKET_RECEIVE_EVENTS.USER_SETTINGS, userSettingsListener)
        }

        return () => {
            if (socket) {
                socket.removeAllListeners(WEBSOCKET_RECEIVE_EVENTS.USER_SETTINGS);
            }
        };
    }, [socket]);

    return (
        <UserSettingsContext.Provider value={{ userSettings, setUserSettings }}>
            {children}
        </UserSettingsContext.Provider>
    );
}

export const useUserSettings = () => {
    return useContext(UserSettingsContext);
};