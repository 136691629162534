import * as React from 'react';
import DefaultHeader from "../../components/DefaultHeader";
import Stack from "@mui/joy/Stack";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Divider from "@mui/joy/Divider";
import {formatCamelCaseToHumanReadable, renderProfileData} from "./utils";
import Card from "@mui/joy/Card";
import PersonalCard from "./components/cards/personal/PersonalCard";
import {useUserTradingProfile} from "../../components/context/UserTradingProfileContext";

const UserProfile: React.FC = () => {
    const {userProfile} = useUserTradingProfile();

    return (
        <>
            <DefaultHeader title="My profile"/>
            <Stack
                spacing={4}
                sx={{
                    display: 'flex',
                    flex: 1,
                    py: 3,
                    width: '95%',
                    maxWidth: '800px',
                    alignSelf: 'center'
                }}
            >
                <PersonalCard />
                {Object.entries(userProfile).map(([title, props]) => (
                    <Card key={title}>
                        <Box sx={{mb: 1}}>
                            <Typography level="title-md">{formatCamelCaseToHumanReadable(title)}</Typography>
                            {/*<Typography level="body-sm">*/}
                            {/*    Additional context needed to provide the most relevant communication experience*/}
                            {/*</Typography>*/}
                        </Box>
                        <Divider/>
                        {renderProfileData(props)}
                    </Card>
                ))}
            </Stack>
        </>
    );
}

export default UserProfile;